import ScrollAnimation from 'react-animate-on-scroll';
import styles from './styles.module.scss';

const NefrologiaArticle = (): JSX.Element => {

    return (
        <article id="nefrologia-pediatrica" className={styles.article}>
            <ScrollAnimation animateIn="fadeInLeft" className={styles.rocket}>
                <img src="/assets/image/art-rocket.svg" alt="Grafismo foguete" loading="lazy" />
            </ScrollAnimation>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <ScrollAnimation animateIn="fadeInLeft">
                            <h2 className={styles.title}>Nefrologia Pediátrica</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p>A <strong>Nefrologia Pediátrica</strong> é a área dentro da Pediatria que cuida da <strong>saúde dos rins e vias urinárias</strong>, ou seja, ureteres, bexiga e uretra. Portanto, tem como objetivo a <strong>prevenção, investigação, diagnóstico e tratamento de doenças</strong> que acometem o <strong>trato urinário</strong> desde o período gestacional até a adolescência.</p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p>Durante os primeiros anos de vida, os rins estão se desenvolvendo e são muito <strong>vulneráveis a lesões</strong>. Além disso, muitas das doenças renais são, no início, silenciosas, ou seja, com sinais e sintomas pouco específicos, o que pode fazer com que o diagnóstico seja tardio e incompleto, trazendo danos não só aos rins, mas também a outros órgãos. </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p>Portanto, o <strong>diagnóstico precoce</strong> dessas doenças, bem como o tratamento e acompanhamento adequado são <strong>fundamentais</strong> para a proteção dos rins e trato urinário como um todo. </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p>A <strong>prevenção de doenças</strong> renais começa antes da gravidez, durante a gravidez e por toda a infância.</p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft">
                            <p className="text-larger"><a href="https://wa.me/5527988633284" target="_blank" rel="noreferrer" className={styles.linkConsulta}>Clique aqui</a>, agende uma consulta agora mesmo e mantenha os rins de seu filho seguro!</p>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-5">
                        <ScrollAnimation animateIn="zoomIn">
                            <img src="/assets/image/art-nephrology.svg" alt="Cuidado desde a gestação a toda a infância" loading="lazy" className={styles.ilustracao} />
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default NefrologiaArticle;
