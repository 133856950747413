import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import classNames from "classnames";
import { HelperTextError } from "components/Error";
import IconComponent, { Icon } from "components/Icon";
import { ErrorMessage } from "formik";
import {
  CSSProperties,
  FormEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import InputMask from "react-input-mask";
import { contactWithFormik } from "store/formik/contact";
import styles from "./styles.module.scss";

const MESSAGE_HEIGHT = 185;
const INPUT_STYLE: CSSProperties = {
  height: "35px",
  paddingTop: "0",
  paddingBottom: "0",
};
const LABEL_STYLE: CSSProperties = {
  textTransform: "uppercase",
  fontFamily: "Baloo Bhai 2",
  color: "#ccc",
};

type SendMessageStatus = "sucess" | "error" | undefined;

interface Fields {
  nome: string;
  assunto: string;
  email: string;
  telefone: string;
  mensagem: string;
}

interface Props {
  values: Fields;
  handleChange: any;
  handleBlur: any;
  errors: Fields;
  isValid: boolean;
  touched: Fields;
  handleSubmit: FormEventHandler;
}

interface ContactItemProps {
  icon: Icon;
  context: JSX.Element;
}

interface ContactResponse {
  success: boolean;
  data: any;
}

const ContactItem = ({ icon, context }: ContactItemProps): JSX.Element => {
  return (
    <div className={styles.contactItem}>
      <div>
        <IconComponent icon={icon} className={styles.iconContato} />
      </div>
      <div>{context}</div>
    </div>
  );
};

const ContatoArticle = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  isValid,
  touched,
}: Props): JSX.Element => {
  const [focusedField, setFocusedField] = useState("");
  const [showSendMessage, setshowSendMessage] =
    useState<SendMessageStatus>(undefined);
  const [sendingMessage, setSendingMessage] = useState(false);

  const formSubmit = useCallback(() => {
    if (!sendingMessage && isValid) {
      console.log(JSON.stringify(values));
      setSendingMessage(true);
      fetch("https://drarani.com.br/php_mailer/contato.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json() as Promise<ContactResponse>)
        .then((jsonRes) => {
          console.log(jsonRes);
          setshowSendMessage(jsonRes.success ? "sucess" : "error");
        })
        .catch(() => setshowSendMessage("error"))
        .finally(() => setSendingMessage(false));
    }
  }, [values, isValid, sendingMessage]);

  const telMask = useMemo(() => {
    return values.telefone.replace(/[^0-9]/g, "").length > 10
      ? "(99) 99999 9999"
      : "(99) 9999 99999";
  }, [values.telefone]);

  return (
    <article id="contato" className={styles.article}>
      <div className="container">
        <ScrollAnimation animateIn="fadeInUp">
          <h2 className={styles.title}>Contato</h2>
        </ScrollAnimation>
        <div className="row justify-content-center">
          <div className="col-8">
            <ScrollAnimation animateIn="fadeInUp">
              <p className="text-center">
                Para agendar consultas, tirar dúvidas ou registrar sugestões,
                entre em contato através do e-mail, WhatsApp, telefones ou
                preenchendo o formulário abaixo. Será um prazer lhe ouvir e
                esclarecer todas as suas dúvidas!
              </p>
            </ScrollAnimation>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 mb-5">
            <ScrollAnimation
              animateIn="fadeInLeft"
              className={styles.boxContatos}
            >
              <h3>Nephros Nefrologia – Vitória</h3>
              <ContactItem
                context={
                  <a href="mailto:contato@drarani.com.br">
                    contato@drarani.com.br
                  </a>
                }
                icon="arroba"
              />
              <ContactItem
                context={
                  <a
                    href="https://wa.me/5527988633284"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (27) 98863 3284
                  </a>
                }
                icon="whatsapp"
              />
              <ContactItem
                context={<a href="tel:2732070937">(27) 3207 0937</a>}
                icon="phone"
              />
              <ContactItem
                context={
                  <a
                    href="https://maps.app.goo.gl/AHzoVrZQDEBKrcW98"
                    target="_blank"
                    rel="noreferrer"
                  >
                    R. José Alexandre Buaiz, 350
                    <br /> Sala 704, Enseada do Suá – Vitória/ES
                  </a>
                }
                icon="pin"
              />

              <a
                className={styles.btnAgende}
                href="https://wa.me/5527988633284"
                target="_blank"
                rel="noreferrer"
              >
                Agende uma consulta
              </a>
            </ScrollAnimation>
          </div>
          <div className="col-lg-6 mb-5">
            <ScrollAnimation
              animateIn="fadeInRight"
              className={styles.boxContatos}
            >
              <h3>Instituto Lílie – Vila Velha</h3>
              <ContactItem
                context={
                  <a href="mailto:contato@drarani.com.br">
                    contato@drarani.com.br
                  </a>
                }
                icon="arroba"
              />
              <ContactItem
                context={
                  <a
                    href="https://wa.me/5527992050803"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (27) 99205 0803
                  </a>
                }
                icon="whatsapp"
              />
              <ContactItem
                context={<a href="tel:2732083734">(27) 3208 3734</a>}
                icon="phone"
              />
              <ContactItem
                context={
                  <a
                    href="https://goo.gl/maps/5w4vHyGFY71Jcbzs8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Av. Dr. Olívio Lira, 353 – Anexo ao Shopping Praia da Costa
                    <br />
                    Torre Leste – Sala 310 Vila Velha/ES
                  </a>
                }
                icon="pin"
              />

              <a
                className={styles.btnAgende}
                href="https://wa.me/5527992050803"
                target="_blank"
                rel="noreferrer"
              >
                Agende uma consulta
              </a>
            </ScrollAnimation>
          </div>
        </div>

        <h3 className={styles.formTitle}>Nos mande uma mensagem!</h3>

        <form
          noValidate
          onSubmit={(evt) => {
            handleSubmit(evt);
            formSubmit();
          }}
        >
          <div className="row">
            <div className="col-lg-5 d-none d-lg-flex justify-content-center">
              <div style={{ width: "50%" }}>
                <img src="/assets/image/icon-pc-message.svg" alt="Ícone PC" />
              </div>
            </div>
            <div className="col">
              {showSendMessage ? (
                showSendMessage === "sucess" ? (
                  <div
                    className={classNames(
                      styles.message,
                      styles.message_success,
                      "text-center"
                    )}
                  >
                    <h3>Mensagem enviada com sucesso!</h3>
                    <p>
                      Aguarde, sua mensagem será respondida assim que possível.
                    </p>
                    <p>
                      <b>Obrigado!</b>
                    </p>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      styles.message,
                      styles.message_error,
                      "text-center"
                    )}
                  >
                    <h3>Epa...</h3>
                    <p>
                      Tivemos um problema ao enviar sua mensagem. Por favor
                      utilize o{" "}
                      <a href="mailto:contato@drarani.com.br">email</a>,{" "}
                      <a
                        href="https://wa.me/5527988633284"
                        target="_blank"
                        rel="noreferrer"
                      >
                        WhatsApp
                      </a>{" "}
                      ou um dos nossos telefones, ou tente novamente mais tarde.
                    </p>
                    <p>
                      <b>Obrigado!</b>
                    </p>
                  </div>
                )
              ) : (
                <div className="row">
                  <div className="col-sm">
                    <ScrollAnimation animateIn="fadeInLeft">
                      <div className={styles.formControlWrapper}>
                        <FormControl fullWidth>
                          <TextField
                            name="nome"
                            label="Nome"
                            error={!!errors.nome && !!touched.nome}
                            variant="outlined"
                            value={values.nome}
                            onChange={handleChange}
                            onBlur={(ev) => {
                              handleBlur(ev);
                              setFocusedField("");
                            }}
                            onFocus={(_) => setFocusedField("nome")}
                            inputProps={{ style: INPUT_STYLE }}
                            InputLabelProps={{
                              style: {
                                ...LABEL_STYLE,
                                ...(focusedField !== "nome" &&
                                  !values.nome && { top: "-9px" }),
                              },
                            }}
                          />
                          <ErrorMessage
                            component={HelperTextError}
                            name={"nome"}
                          />
                        </FormControl>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft">
                      <div className={styles.formControlWrapper}>
                        <FormControl fullWidth>
                          <TextField
                            name="email"
                            label="Email"
                            error={!!errors.email && !!touched.email}
                            type="email"
                            margin="none"
                            variant="outlined"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={(ev) => {
                              handleBlur(ev);
                              setFocusedField("");
                            }}
                            onFocus={(_) => setFocusedField("email")}
                            inputProps={{ style: INPUT_STYLE }}
                            InputLabelProps={{
                              style: {
                                ...LABEL_STYLE,
                                ...(focusedField !== "email" &&
                                  !values.email && { top: "-9px" }),
                              },
                            }}
                          />
                          <ErrorMessage
                            component={HelperTextError}
                            name={"email"}
                          />
                        </FormControl>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft">
                      <div className={styles.formControlWrapper}>
                        <FormControl fullWidth>
                          <InputMask
                            mask={telMask}
                            disabled={false}
                            onChange={handleChange}
                            onBlur={(ev) => {
                              handleBlur(ev);
                              setFocusedField("");
                            }}
                            onFocus={(_) => setFocusedField("telefone")}
                            value={values.telefone}
                          >
                            {() => (
                              <TextField
                                name="telefone"
                                label="Telefone"
                                error={!!errors.telefone && !!touched.telefone}
                                type="tel"
                                margin="none"
                                variant="outlined"
                                inputProps={{ style: INPUT_STYLE }}
                                InputLabelProps={{
                                  style: {
                                    ...LABEL_STYLE,
                                    ...(focusedField !== "telefone" &&
                                      !values.telefone && { top: "-9px" }),
                                  },
                                }}
                              />
                            )}
                          </InputMask>
                          <ErrorMessage
                            component={HelperTextError}
                            name={"telefone"}
                          />
                        </FormControl>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInLeft">
                      <div className={styles.formControlWrapper}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="assunto-label"
                            style={{
                              ...LABEL_STYLE,
                              ...(focusedField !== "assunto" && !values.assunto
                                ? { top: "-14px", left: "14px" }
                                : {
                                    top: "-7px",
                                    left: "14px",
                                    background: "white",
                                    zIndex: 9,
                                    padding: "0 2px",
                                  }),
                            }}
                          >
                            Assunto
                          </InputLabel>
                          <Select
                            name="assunto"
                            error={!!errors.assunto && !!touched.assunto}
                            variant="outlined"
                            value={values.assunto}
                            onChange={handleChange}
                            onFocus={(_) => setFocusedField("assunto")}
                            onBlur={(ev) => {
                              handleBlur(ev);
                              setFocusedField("");
                            }}
                            style={INPUT_STYLE}
                          >
                            <MenuItem value="Dúvida">Dúvida</MenuItem>
                            <MenuItem value="Sugestão">Sugestão</MenuItem>
                            <MenuItem value="Reclamação">Reclamação</MenuItem>
                            <MenuItem value="Outro">Outro</MenuItem>
                          </Select>
                          <ErrorMessage
                            component={HelperTextError}
                            name={"assunto"}
                          />
                        </FormControl>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-sm">
                    <ScrollAnimation animateIn="fadeInRight">
                      <div className={styles.formControlWrapper}>
                        <FormControl fullWidth>
                          <TextField
                            name="mensagem"
                            label="Mensagem"
                            error={!!errors.mensagem && !!touched.mensagem}
                            margin="none"
                            variant="outlined"
                            multiline
                            fullWidth
                            inputProps={{
                              style: {
                                ...INPUT_STYLE,
                                height: `${MESSAGE_HEIGHT}px`,
                              },
                            }}
                            InputLabelProps={{ style: LABEL_STYLE }}
                            value={values.mensagem}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component={HelperTextError}
                            name={"mensagem"}
                          />
                        </FormControl>
                      </div>
                    </ScrollAnimation>
                    <div className="text-right">
                      <ScrollAnimation animateIn="fadeInRight">
                        {!sendingMessage ? (
                          <button type="submit" className={styles.submitButton}>
                            Enviar
                          </button>
                        ) : (
                          <p>Enviando...</p>
                        )}
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </article>
  );
};

export default memo(contactWithFormik(ContatoArticle as any));
